@import url('https://fonts.googleapis.com/css2?family=Marck+Script&family=Style+Script&display=swap');

.second-hero-section{
    height: 75vh;
    background-position: center;
    background-size: cover;
}

.title-heading{
    font-family: "Style Script", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 4rem;

}