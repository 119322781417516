@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');

.navbar{
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,137,46,1) 100%);
}

#navbar-top {
    background: transparent !important;
    color: black;
  }


.navbar .navbar-toggler {
    border-color: white;
}

.navbar .navbar-toggler-icon,
.navbar .navbar-brand img {
    filter: invert();
}

.navbar .navbar-brand img {
    transform: rotate(45deg);
    width: 1.5rem;
}

.nav-user.text-uppercase.fw-semibold .dropdown-toggle.nav-link{
    color: white;
}

.logo-img{
    font-family: "Marck Script", cursive;
    font-weight: 600;
    font-style: normal;
    font-size: 1.5rem;
}

@media screen and (max-width:576px){
    .navbar{
        background-color: var(--secondary-bg-color);
    }
}