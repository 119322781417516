.quick-links-section{
    margin-top: 8%;
    margin-bottom: 7%;
}

@media screen and (max-width:992px){

    .quick-links-section{
        margin-top: 90%;
        margin-bottom: 7%;
    }

    .col-lg-3{
        margin-bottom: 2%;
    }
}

@media screen and (max-width:700px){

    .quick-links-section{
        margin-top: 110%;
        margin-bottom: 5%;
    }
}

@media screen and (max-width:430px){

    .quick-links-section{
        margin-top: 127%;
        margin-bottom: 5%;
    }
  
  }