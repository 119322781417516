@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

.destination-text{
    font-family: "Kaushan Script", cursive;
    font-weight: 700;
    font-size: 2rem;
}

.pack-btn{
    margin-top: 100px;
   font-size: large;
}

.pack-btn button{
    margin: 0 0 2% 2%;
}

.pack-btn .group, .pack-btn .custom{
    font-family: "Kaushan Script", cursive;
    margin: auto;
    font-size: 1.5rem;
    font-weight: 300;
    text-underline-position: below;
}

.card-header{
    margin-top: 8px;
    font-size: 2rem;

}

.pack-btn a{
    margin-bottom: 1%;
    text-decoration: underline;
    font-weight: 700;
    transition: .3s linear ease-in-out;
    color: white;
}

.pack-btn a:hover{
    color: #e45d10;
}

.package-card img{
    height: 200px;
    background-image: linear-gradient(rgba(0,0,0,0.10), rgba(0,0,0,0.50));
    transition: transform 0.3s ease-in-out;
}

.package-card:hover img{
    transform: scale(1.1)
}


.package-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 40, 40, 0.5); /* Semi-transparent overlay */
    opacity: 1;
    transition: opacity 0.3s ease; /* Smooth transition */
}

 