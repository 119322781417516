@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

.carousel-item1{
    background-image: linear-gradient(to right, #0a0d14 , transparent), url('../../Assets/PackageImages/ANDA-BG.jpg');
}

.carousel-item2{
    background-image: linear-gradient(to right, #0a0d14 , transparent), url('../../Assets/PackageImages/KASH-BG.jpg');
}

.carousel-item3{
    background-image: linear-gradient(to left, #0a0d14 , transparent), url('../../Assets/PackageImages/SHKUMA-BG.jpg');
}

.carousel-item4{
    background-image: linear-gradient(to right, #0a0d14 , transparent), url('../../Assets/PackageImages/MUMGOA-BG.jpg');
}

.carousel-item1,
.carousel-item2,
.carousel-item3,
.carousel-item4{
    background-size: cover;
    background-position: center;
}

.header-title{
    font-family: "Kaushan Script", cursive;
    font-weight: 500;
}


@media screen and (max-width: 992px) {
    .carousel-item1{
        background-image: linear-gradient(to right, rgba(0,0,0,0.50), rgba(0,0,0,0.50)), url('../../Assets/PackageImages/ANDA-BG.jpg');
    }
    
    .carousel-item2{
        background-image: linear-gradient(to right, rgba(0,0,0,0.50), rgba(0,0,0,0.50)), url('../../Assets/PackageImages/KASH-BG.jpg');
    }
    
    .carousel-item3{
        background-image: linear-gradient(to right, rgba(0,0,0,0.40), rgba(0,0,0,0.40)), url('../../Assets/PackageImages/SHKUMA-BG.jpg');
    }

    .carousel-item4{
        background-image: linear-gradient(to right, rgba(0,0,0,0.40), rgba(0,0,0,0.40)), url('../../Assets/PackageImages/MUMGOA-BG.jpg');
    }
    
}