.view-all .second-hero-section {

    background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.4)), url(../../Assets/Images/destinations-recommended-section-bg.jpg);
    background-size: cover;
    height: 25vh;

}

.pack .package-card{
    width: 23rem !important;
}