.gallery{
    height: 60%;
    width: 100%;
    display: flex;
    margin: 0 auto 0;
    box-sizing: border-box;
}

.image-box {
    margin: 0 0;
    background: linear-gradient(90deg, rgba(73,71,71,1) 16%, rgba(2,3,2,1) 75%);
    flex-grow: 1;
    flex-basis: 0;
    transition: .5s linear ease-in-out;
}
.image-box img {
    z-index: -1;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s linear ease-in-out;
}
/* .image-box:hover{
    flex-basis: 20%;
}

.image-active{
    flex-basis: 20%;
}

.gallery:hover .image-active{
    flex-basis: 0;

} */

.image-box:nth-child(3){
    flex-basis: 20%;
}