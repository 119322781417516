.wrapper-form{
  margin-top: 190px;
}

.flight-form{
    position: absolute;
    width: 90%;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,137,46,1) 100%);
    margin-top: 1300px;
    top:85%;
    left: 50%;
    transform: translate(-50%, -50%);

}

/* .btn{
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
  color: black !important;
  border: 1px solid #52ad5c  !important;
}

.btn:hover{
  background: linear-gradient(90deg, rgba(173,222,61,1) 16%, rgba(106,191,118,1) 75%);
  color: rgb(18, 17, 17) !important;
  border: 1px solid #253927  !important;
} */

#radio-1{
    display: none;
}
#radio-2{
    display: none;
}


.col.d-flex.justify-content-start .active{
    display: block;
    border: 2px solid white;
    color: white;
    width: 120px;
    padding: 10px 13px;
    border-radius: 30px;
    background-color: var(--secondary-color);
    font-weight: 500;
    font-size: 1.1rem;
    cursor: pointer;
}

.inactive{
    display: block;
    border: 2px solid white;
    color: white;
    width: 120px;
    padding: 10px 13px;
    border-radius: 30px;
    background-color: var(--primary-color);
    cursor: pointer;
}

.theme , .row.mt-2.row-cols-lg-5.row-cols-sm-1 .col .mb-3 {
  background-color: white;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 10px;
  margin: 2%;
  width: 100%;
  height: 80px;
}

.row.mt-2.row-cols-lg-5.row-cols-sm-1 .col .mb-3 span{
  cursor: pointer;
}

.headerSearchItem input[type="text"]{
    width: 100%;
    border: none;
    font-weight: 600;
    border-radius: 10px;
    position: relative;

}

#travel {
  display: none;
}
.btn{
  font-size: 1rem;
}

.btn-icon{
  font-size: 40px;
}


@media screen and (max-width:992px){

  .theme{
    width: 98%;
  }
  
  .flight-form{
    top: 130%;
    padding: auto;
    margin-top: 185px;
  }
  .row.mt-2.row-cols-lg-5.row-cols-sm-1 .col .mb-3{
    margin-top: 9%;
  }


}

@media screen and (max-width:912px){

  .flight-form{
    top: 100%;
    padding: auto;
    margin-top: 185px;
  }
}

@media screen and (max-width:700px){

  .options{
    top: 185%;
  }
  .flight-form{
    top: 110%;
  }

}

@media screen and (max-width:430px){

  .options{
    top: 185%;
  }
  .flight-form{
    top: 77%;
  }

}




