.holiday-hero-section .second-hero-section {

    background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.4)), url(../../Assets/Images/header-img1.jpg);
    height: 50vh;

}

.package-search{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.package-search form{
    width: 70%;
    display: flex;
    justify-content: center;
    button {
        padding: 0px 8px;
        font-size: 20px;
        cursor: pointer;
        height: 38px;
    }
}

.package-search form input[type='text']{
    font-size: 18px;
    width: 100%;
    padding: 5px 5px 5px 10px;
    height: 38px;
    outline: none;
}