@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.andaman-group-hero-section .second-hero-section {

    background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.4)), url(../../Assets/PackageImages/ANDA-BG.jpg);
    height: 40vh;
}



.itinerary-div{
    width: 70%;
} 

.itenary-content{
    width: 71%;
    cursor: pointer;
}

.ite-content{
    font-size: 1.2rem;
}

.book-div{
    width: 25%;
}

.style-script-regular {
    font-family: "Style Script", cursive;
    font-weight: 700;
    font-style: normal;
    font-size: 2rem;
  }

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

