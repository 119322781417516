@import url('https://fonts.googleapis.com/css2?family=Marck+Script&family=Style+Script&display=swap');

.package-header{
    font-family: "Marck Script", cursive;
    font-weight: 400;
    font-style: normal;
    background: linear-gradient(90deg, rgba(0,247,82,1) 0%, rgba(219,240,5,1) 100%);
    color: black;
    /* animation: blink-neon 1s infinite;
    font-size: 20px;
    text-align: center; */
    border-radius: 50%;
}


 .slick-prev:before, .slick-next:before  {
  color: black !important;
}

.swiper {
  width: 100%;
  padding-top: 5%;
  padding-bottom: 10%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 400px;
  height: 430px;
}

.swiper-slide .package-card {
  display: block;
  width: 100%;
}

@media only screen and (max-width: 768px){
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 400px;
    height: 520px;
  }
}