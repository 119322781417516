@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');

.wrapper h1{
    font-family: "Rowdies", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.main_{
    display: flex;
    justify-content: center;
    align-items: center;
}
.side-image{
    background-image: url(../../Assets/Images/bali-resort2.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px 0 0 10px;
    position: relative;
}
#row{
  width:  1900px;
  height:550px;
  border-radius: 10px;
  background: #fff;
  padding: 0px;
  box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.2);
}
.text_{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.text_ p{
    color: #fff;
    font-size: 20px; 
}

.right{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.input-box{
  width: 330px;
  box-sizing: border-box;
}
.side-image img{
    width: 35px;
    position: absolute;
    top: 30px;
    left: 30px;
}
.input-box header{
    font-weight: 700;
    text-align: center;
    margin-bottom: 45px;
}
.input-field{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 10px 0 10px;
}
.input_{
    height: 45px;
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    margin-bottom: 20px;
    color: #40414a;
}
.input-box .input-field label{
    position: absolute;
    top: 10px;
    left: 10px;
    pointer-events: none;
    transition: .5s;
}
.input-field input:focus ~ label
   {
    top: -10px;
    font-size: 13px;
  }
  .input-field input:valid ~ label
  {
   top: -10px;
   font-size: 13px;
   color: #5d5076;
 }
 .input-field .input_:focus, .input-field .input_:valid{
    border-bottom: 1px solid var(--primary-color);
 }
 .submit_{
    border: none;
    outline: none;
    height: 45px;
    background: var(--primary-color);
    border-radius: 5px;
    transition: .4s;
 }
 .submit_:hover{
    background: var(--secondary-color);
    color: #fff;
 }
 .signin{
    text-align: center;
    font-size: small;
    margin-top: 25px;
}
.span a{
    text-decoration: none;
    font-weight: 700;
    color: #000;
    transition: .5s;
}
.span a:hover{
    text-decoration: underline;
    color: #000;
}
@media only screen and (max-width: 768px){
    .side-image{
        border-radius: 10px 10px 0 0;
    }
    .side-image img{
        width: 35px;
        position: absolute;
        top: 20px;
        left: 47%;
    }
    .text_{
        position: absolute;
        top: 70%;
        text-align: center;
    }
    .text_ p, i{
        font-size: 16px;
    }
    #row{
        max-width:420px;
        width: 100%;
    }
}