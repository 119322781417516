@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

.andaman-section-grid{    
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}
.destination-text-andaman{
    font-family: "Kaushan Script", cursive;
    font-weight: 300;
    font-size: 2rem;
}
.andaman-section-card{
    position: relative;
}

.andaman-section-card::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: linear-gradient(to top, #24272e, transparent);
}

.andaman-content{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
    z-index: 1;
}