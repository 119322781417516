@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body, h1, h2, h3, h4, h5, h6, p, div {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
code {
  font-family: "Inter", sans-serif;
}
